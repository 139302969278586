import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Design issue to be moved to Github`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "/visualization-of-polis-data",
            "title": "visualization of polis data"
          }}>{`visualization of polis data`}</a>{` to participants has been met with mixed reception and needs to be redesigned. Here, we'll handle the participation interface. The report redesign will be covered in a different document.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`General feedback`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The participation interface visualization is distinctive and partly defines the polis brand`}</li>
          <li parentName="ul">{`The visualization is part of the brand and has been communicated about in blog posts and media such as "`}<strong parentName="li">{`MIT Tech Review`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.technologyreview.com/2017/06/02/151413/the-internet-doesnt-have-to-be-bad-for-democracy/"
            }}>{`article`}</a>{` by Tom Simonite"`}</li>
          <li parentName="ul">{`People want a simpler visualization and have gravitated towards the `}<a parentName="li" {...{
              "href": "/comment-list-visualization",
              "title": "comment list visualization"
            }}>{`comment list visualization`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Features, ideally`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Can handle up to 10 clusters`}</li>
          <li parentName="ul">{`📱Handles viewing on mobile`}</li>
          <li parentName="ul">{`🔵Handles letting person know what cluster they are in (the blue self dot, which also appears in the abandoned `}<a parentName="li" {...{
              "href": "/patent",
              "title": "patent"
            }}>{`patent`}</a>{` application for `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{`)`}
            <ul parentName="li">
              <li parentName="ul">{`requires users to sign in and requires quite a bit of extra logic on the client`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Categorizes comments based on whether they are `}<a parentName="li" {...{
              "href": "/group-informed-consensus",
              "title": "group informed consensus"
            }}>{`group informed consensus`}</a>{` or `}<a parentName="li" {...{
              "href": "/repful",
              "title": "repful"
            }}>{`repful`}</a>{``}</li>
          <li parentName="ul">{`Handles real time updates well, ie., as more people vote the visualization changes in a coherent way`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The original polis visualization has 🔵 & 📱, and an handle a few groups (horizontal space is limited for the buttons on mobile). It handles real-time updates well because it uses a custom built PCA algo that incrementally updates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`As we move to much finer grained clustering, it may or may not be stable from run to run. And running the clusters on every vote seems unwise. So... if we're going to give people 10x the visibility into group structure, showing much more of the local structure across the conversation, how do we handle the ideal feature set?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When we switch to Leiden, we `}<em parentName="p">{`could`}</em>{` also switch to visualizing clusters in UMAP, or we `}<em parentName="p">{`could`}</em>{` cluster in Leiden and visualize in PCA space:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Is this UMAP with the sparsity correction?`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`So why not just use the original polis visualization with more clusters? Well... we can. The problem is that the granularity is such that it's hard to differentiate clusters. Let's say there are 10 groups. Groups 9 and 10 are exactly the same across 23 of the 25 comments we surface on the client to explain why the groups are what they are. The combinatorics of figuring out how exactly two groups are different is difficult. It's not usually how it's done.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Usually, 10+ clusters across dozens of factors (in this case comments) are shown as a heatmap. To get straight to the point, here's what that might look like in the participation interface (`}<a parentName="p" {...{
            "href": "https://www.figma.com/file/tZ0Ms3GHNuR6OtH3iQicZD/polis-heatmap?node-id=0%3A1"
          }}>{`FIGMA`}</a>{`):`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The heatmap visualization intends to handle a number of problems with displaying cluster data, especially as the participation interface transitions from using `}<a parentName="p" {...{
            "href": "/PCA",
            "title": "PCA"
          }}>{`PCA`}</a>{` and `}<a parentName="p" {...{
            "href": "/k-means",
            "title": "k means"
          }}>{`k means`}</a>{` to `}<a parentName="p" {...{
            "href": "/Leiden",
            "title": "Leiden"
          }}>{`Leiden`}</a>{` graph based clustering.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`The heatmap can handle more clusters and show differentiation.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Above, Each heatmap cell's color signifies 'average vote' from -1 to 1 within the group using more saturation (darker blue darker red the closer to extreme values).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Here's an example off the internet that maps color saturation to 1 -1, and size bubble to the |absolute value| of the average`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.researchgate.net/figure/Heatmap-of-the-linear-correlation-between-the-characteristics-of-milk-performance-and_fig3_324212299"
            }}>{`https://www.researchgate.net/figure/Heatmap-of-the-linear-correlation-between-the-characteristics-of-milk-performance-and_fig3_324212299`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`So, using our app colors and real conversation data, we might do something like this:`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A single metric for the lay crowd: `}<strong parentName="p">{`how did this group vote on average on this statement`}</strong>{`?`}</p>
        <ul parentName="li">
          <li parentName="ul">{`A big circle is overwhelmingly agree`}</li>
          <li parentName="ul">{`A small circle is 'lean agree', and could end up being a proxy for low vote count noise (3 agree 2 disagree very small green)`}</li>
          <li parentName="ul">{`Empty means no voting, which is nice, no information`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A problem here is that we rely primarily on color.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`There's no room to add a special symbol on small circles.`}</li>
          <li parentName="ul">{`We could put a `}<inlineCode parentName="li">{`1px`}</inlineCode>{` line across it if it's a disagree, something like that`}</li>
          <li parentName="ul">{`Or like an anti! 🚫`}</li>
          <li parentName="ul">{`We have that icon already so it would make sense of we did a white line through it`}</li>
          <li parentName="ul">{`Probably heavier (2px 3px) if it's bigger, and 1px if it's smaller, so need dynamic 🚫 sizing there`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Note that since these groups might change completely after each vote, we run it once every hour instead and update in bulk.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`So this visualization probably wouldn't change while the person was voting — unknown whether this would encourage them to come back`}</li>
          <li parentName="ul">{`Hopefully`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There are three questions one might ask that this doesn't answer`}</p>
        <ol parentName="li">
          <li parentName="ol">{`(1) How many people are in each group?`}</li>
          <li parentName="ol">{`(2) How many people in group A voted on comment X, in absolute terms?`}</li>
          <li parentName="ol">{`(3) How many people in group A agreed on comment X, given that we're showing a small green dot? Does that mean it was split?`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Historically, we've answered this questions with a stacked bar chart`}</p>
        <ul parentName="li">
          <li parentName="ul">{`But it's confusing because it's unclear, at first glance, what the grey and empty bits are and how they relate. It's hard to pick out patterns because information isn't dense enough.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Regarding "(1) How many people are in each group?", It is reasonable that we ignore group size when visualizing the dots. We want to highlight small groups with equal weighting — who knows, maybe they're experts. Nevertheless, we can show that at the top and use the group size and order as the identifier. That is shown in the above image under the heading `}<strong parentName="p">{`Groups, by # of members`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Regarding "(2) How many people in group A voted on comment X, in absolute terms?" and "(3) How many people in group A agreed on comment X, given that we're showing a small green dot? Does that mean it was split?"...`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`We COULD then add an option to control for how many participants in the group voted on the comment, making this more complicated view opt-in. That would be a simple button to add, and an easy legend to make. Here's an example of what that might look like in our current colors (is it orangy and bluey enough to be accessible)? Not enough that we avoid having a colorblind button in the report, at present...`}</p>
            <ul parentName="li">
              <li parentName="ul">{`So the mapping here, is:`}
                <ul parentName="li">
                  <li parentName="ul">{`The color AND size of the colored circle relative to the grey circle map to the percentage voted.`}
                    <ul parentName="li">
                      <li parentName="ul">{`So if 100% of people who voted agreed, it's a solid green circle of any size without grey`}</li>
                      <li parentName="ul">{`If 100% of people who voted agreed and it's a full size circle, everyone in the group saw it and agreed.`}</li>
                      <li parentName="ul">{`What's nice about that is if the grey circle is full and the green or red circle is small, you can assume that the group was split because a lot of people in the group saw it but many voted the other way even though the average is showing, etc`}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`I think it's simpler than the stacked bars, still, because the difference between the inner and outer`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`There is LESS RISK OVERALL of having so many split groups when we're doing graph based clustering and showing so much more of the local structure as a result.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We can show the blue dot by highlighting the number over the cluster the user is in`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We can make the comment multi-line and scale the dot if there are fewer groups, or cut the comment short and allow click to show full. No way around it, this is tight on a phone. But it's also possible. You can skim the pattern and click to see full comment text.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We might also tell the users how many comments we've surfaced. This should be the smallest number of comments necessary to tell the story. So '24 out of 741 comments' lets them know how much data is missing. As we get "See `}<a parentName="p" {...{
            "href": "https://github.com/compdemocracy/polis-issues/issues/123%22"
          }}>{`https://github.com/compdemocracy/polis-issues/issues/123"`}</a>{` importance in place, we can use importance to surface a better set of statements.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`See also, regarding importance metrics `}<a parentName="li" {...{
              "href": "/showing-importance-if-we-use-a-dot-matrix",
              "title": "showing importance if we use a dot matrix"
            }}>{`showing importance if we use a dot matrix`}</a>{``}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      